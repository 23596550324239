<template>
  <div v-if="isOpen" class="modal-overlay" @click="handleClose">
    <div class="modal-content" @click.stop>
      <div class="modal-header">
        <h3>Rentz Order</h3>
        <button class="close-button" @click="handleClose">&times;</button>
      </div>
      <div class="modal-body">
        <p class="instruction">Drag and drop players to set their finishing order</p>
        <div
          class="players-list"
          @dragover.prevent
          @dragenter.prevent="handleListDragEnter"
          @touchmove.prevent="handleTouchMove"
        >
          <TransitionGroup name="list" tag="div">
            <template v-for="(player, index) in orderedPlayers" :key="player.id">
              <div
                class="drop-zone"
                :data-index="index"
                :data-preview="(draggedPlayer || touchDraggedPlayer)?.name"
                :class="{
                  'drag-over': isDragOverIndex(index),
                  dragging: isDragging
                }"
                @dragenter.prevent="handleDragEnter($event, index)"
                @dragover.prevent="handleDragOver($event, index)"
                @dragleave.prevent="handleDragLeave($event, index)"
                @drop="handleDrop($event, index)"
              ></div>
              <div
                class="player-card"
                :data-index="index"
                :class="{
                  dragging: isDragging && draggedPlayer?.id === player.id,
                  'touch-dragging': touchDraggedPlayer?.id === player.id
                }"
                draggable="true"
                @dragstart="handleDragStart($event, player)"
                @dragend="handleDragEnd"
                @dragenter.prevent="handleDragEnter($event, index)"
                @dragover.prevent="handleDragOver($event, index)"
                @touchstart.prevent="handleTouchStart($event, player, index)"
                @touchmove.prevent
                @touchend.prevent="handleTouchEnd"
              >
                <span class="player-name">{{ player.name }}</span>
                <span class="player-rank">{{ index + 1 }}</span>
              </div>
            </template>
            <div
              key="last-drop-zone"
              class="drop-zone"
              :data-index="orderedPlayers.length"
              :data-preview="(draggedPlayer || touchDraggedPlayer)?.name"
              :class="{
                'drag-over': isDragOverIndex(orderedPlayers.length),
                dragging: isDragging
              }"
              @dragenter.prevent="handleDragEnter($event, orderedPlayers.length)"
              @dragover.prevent="handleDragOver($event, orderedPlayers.length)"
              @dragleave.prevent="handleDragLeave($event, orderedPlayers.length)"
              @drop="handleDrop($event, orderedPlayers.length)"
            ></div>
          </TransitionGroup>
        </div>
      </div>
      <div class="modal-footer">
        <button class="confirm-button" @click="handleConfirm">Confirm Order</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  players: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(['confirm', 'close']);

const orderedPlayers = ref([...props.players]);
const draggedPlayer = ref(null);
const dragOverIndex = ref(null);
const isDragging = ref(false);

// Touch-specific state
const touchDraggedPlayer = ref(null);
const touchStartY = ref(0);
const touchStartIndex = ref(null);

const isDragOverIndex = (index) => {
  return (
    dragOverIndex.value === index &&
    (draggedPlayer.value !== null || touchDraggedPlayer.value !== null)
  );
};

const handleDragStart = (event, player) => {
  draggedPlayer.value = player;
  isDragging.value = true;
  event.target.classList.add('dragging');
};

const handleDragEnd = (event) => {
  event.target.classList.remove('dragging');
  draggedPlayer.value = null;
  dragOverIndex.value = null;
  isDragging.value = false;
};

const handleDragEnter = (event, index) => {
  if (!draggedPlayer.value) return;
  dragOverIndex.value = index;
};

const handleDragOver = (event, index) => {
  if (!draggedPlayer.value) return;
  dragOverIndex.value = index;
};

const handleDragLeave = (event, index) => {
  // Only clear the index if we're not entering a child element
  if (!event.currentTarget.contains(event.relatedTarget)) {
    dragOverIndex.value = null;
  }
};

const handleListDragEnter = () => {
  if (!draggedPlayer.value) return;
  // Keep the current dragOverIndex if it exists
  if (dragOverIndex.value === null) {
    dragOverIndex.value = orderedPlayers.value.length;
  }
};

const handleDrop = (event, targetIndex) => {
  if (!draggedPlayer.value) return;

  const draggedIndex = orderedPlayers.value.findIndex((p) => p.id === draggedPlayer.value.id);
  if (draggedIndex === -1) return;

  const newOrder = [...orderedPlayers.value];
  newOrder.splice(draggedIndex, 1);

  // If dropping after the dragged item's original position, we need to adjust the target index
  const adjustedTargetIndex = targetIndex > draggedIndex ? targetIndex - 1 : targetIndex;
  newOrder.splice(adjustedTargetIndex, 0, draggedPlayer.value);

  orderedPlayers.value = newOrder;
  dragOverIndex.value = null;
};

const handleTouchStart = (event, player, index) => {
  event.preventDefault();
  touchDraggedPlayer.value = player;
  touchStartY.value = event.touches[0].clientY;
  touchStartIndex.value = index;
  isDragging.value = true;
};

const handleTouchMove = (event) => {
  if (!touchDraggedPlayer.value) return;
  event.preventDefault();

  const touch = event.touches[0];
  const elements = document.elementsFromPoint(touch.clientX, touch.clientY);

  // Find drop zones and player cards under the touch point
  const dropZone = elements.find((el) => el.classList.contains('drop-zone'));
  const playerCard = elements.find((el) => el.classList.contains('player-card'));

  let targetIndex = null;

  if (dropZone) {
    targetIndex = parseInt(dropZone.dataset.index);
  } else if (playerCard) {
    targetIndex = parseInt(playerCard.dataset.index);
    // When hovering over a player card, show the drop zone before or after based on touch position
    const rect = playerCard.getBoundingClientRect();
    const middleY = rect.top + rect.height / 2;
    if (touch.clientY > middleY) {
      targetIndex += 1; // Show drop zone after the card
    }
  }

  if (!isNaN(targetIndex)) {
    dragOverIndex.value = targetIndex;
  }
};

const handleTouchEnd = (event) => {
  event.preventDefault();
  if (!touchDraggedPlayer.value || dragOverIndex.value === null) {
    touchDraggedPlayer.value = null;
    isDragging.value = false;
    return;
  }

  const draggedIndex = touchStartIndex.value;
  const targetIndex = dragOverIndex.value;

  if (draggedIndex !== targetIndex) {
    const newOrder = [...orderedPlayers.value];
    const [removed] = newOrder.splice(draggedIndex, 1);
    newOrder.splice(targetIndex, 0, removed);
    orderedPlayers.value = newOrder;
  }

  touchDraggedPlayer.value = null;
  touchStartIndex.value = null;
  dragOverIndex.value = null;
  isDragging.value = false;
};

const handleConfirm = () => {
  emit('confirm', orderedPlayers.value);
};

const handleClose = () => {
  orderedPlayers.value = [...props.players];
  emit('close');
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--bg-color);
  border-radius: 8px;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
  color: var(--text-color);
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.25rem;
  line-height: 1;
}

.close-button:hover {
  color: var(--text-color);
}

.instruction {
  color: var(--text-secondary);
  margin: 0;
  text-align: center;
}

.players-list {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  padding: 0.5rem;
  position: relative;
  min-height: 200px;
  touch-action: none; /* Prevent scrolling on the entire list */
}

.player-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: move;
  user-select: none;
  transition: all 0.2s ease;
  position: relative;
  transform-origin: center;
  margin: 2px 0;
}

.player-card::before {
  content: '⋮⋮';
  position: absolute;
  left: 0.5rem;
  color: var(--text-secondary);
  font-size: 1.2rem;
  line-height: 1;
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

.player-card:hover::before {
  opacity: 1;
}

.player-card:hover {
  background-color: var(--border-color);
  transform: translateX(0.5rem);
}

.player-card.dragging {
  opacity: 0.5;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.player-name {
  color: var(--text-color);
  font-weight: 500;
  margin-left: 2rem;
}

.player-rank {
  color: var(--text-secondary);
  font-size: 0.9rem;
  background-color: var(--bg-color);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.confirm-button {
  padding: 0.75rem 1.5rem;
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  color: var(--text-color);
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.confirm-button:hover {
  opacity: 0.9;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.drop-zone {
  height: 0;
  transition: all 0.2s ease;
  position: relative;
  border-radius: 4px;
  margin: 0;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
}

.drop-zone.dragging {
  height: 2px;
  opacity: 0;
  pointer-events: all;
}

.drop-zone.drag-over {
  height: 56px;
  background-color: var(--surface-color);
  border: 2px dashed var(--primary-color);
  opacity: 1;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.drop-zone.drag-over::before {
  content: attr(data-preview);
  color: var(--text-color);
  opacity: 0.7;
  margin-left: 2rem;
  font-weight: 500;
}

.drop-zone.drag-over::after {
  content: '⋮⋮';
  color: var(--text-secondary);
  font-size: 1.2rem;
  line-height: 1;
  opacity: 0.5;
  position: absolute;
  left: 0.5rem;
}

.player-card.touch-dragging {
  opacity: 0.5;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

@media (max-width: 480px) {
  .modal-content {
    padding: 1rem;
    width: 95%;
  }

  .player-card {
    padding: 0.75rem;
    touch-action: none; /* Prevents scrolling while dragging on touch devices */
  }
}
</style>
