export const GAME_TYPES = {
  DIAMONDS: 'Diamonds',
  QUEENS: 'Queens',
  LEVATE: 'Levate',
  TEN_OF_CLUBS: 'Ten Of Clubs',
  KING_OF_HEARTS: 'King Of Hearts',
  WHIST: 'Whist',
  RENTZ: 'Rentz',
  TOTALS: 'Totals'
};
