<template>
  <div class="active-game">
    <section class="scores">
      <div class="scores-grid">
        <div
          v-for="(score, index) in gameStore.playerScores.slice(0, gameStore.playerNames.length)"
          :key="index"
          class="player-score"
        >
          <div class="rank-ribbon" :class="getRankClass(gameStore.getPlayerRank(index))">
            {{ gameStore.getPlayerRank(index) }}
          </div>
          <div class="player-header">
            <span class="player-name">{{ gameStore.playerNames[index] }}</span>
          </div>
          <div class="score-details">
            <div class="score-column">
              <span class="score-label">Last</span>
              <span class="round-score" :class="{ negative: score.round < 0 }">{{
                score.round
              }}</span>
            </div>
            <div class="score-column">
              <span class="score-label">Total</span>
              <span class="total-score">{{ score.total }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="gameStore.isGameOver" class="game-over">
      <h2>Game Over!</h2>
      <div class="winner">
        <h3>Winner: {{ gameStore.winner.name }}</h3>
        <p>Final Score: {{ gameStore.winner.score }}</p>
      </div>
      <router-link to="/new-game" class="new-game-button">
        <button>Start New Game</button>
      </router-link>
    </section>

    <section v-else class="game-section">
      <div class="current-player">
        <h3>Current Turn: {{ gameStore.playerNames[gameStore.currentPlayerIndex] }}</h3>
      </div>

      <div class="mini-games">
        <div class="double-points">
          <label class="double-button" :class="{ active: isDouble, disabled: gameStore.availableMiniGames.length === 1 && gameStore.availableMiniGames[0].id === GAME_TYPES.RENTZ }">
            <input v-model="isDouble" type="checkbox" :disabled="gameStore.availableMiniGames.length === 1 && gameStore.availableMiniGames[0].id === GAME_TYPES.RENTZ" />
            <span class="button-text">Double</span>
          </label>
        </div>
        <h4>Available games</h4>
        <div class="mini-games-grid">
          <button
            v-for="game in gameStore.availableMiniGames"
            :key="game.id"
            @click="selectMiniGame(game)"
            :disabled="isDouble && game.id === GAME_TYPES.RENTZ"
            :class="{ disabled: isDouble && game.id === GAME_TYPES.RENTZ }"
          >
            {{ game.displayName }}
          </button>
        </div>
      </div>
    </section>

    <SingleCardInputModal
      :is-open="isSingleCardModalOpen"
      :selected-game="isSingleCardModalOpen && (selectedGame === GAME_TYPES.KING_OF_HEARTS || selectedGame === GAME_TYPES.TEN_OF_CLUBS) ? selectedGame : null"
      :players="gameStore.players"
      @confirm="handleSingleCardConfirm"
      @close="closeSingleCardModal"
    />

    <RentzOrderModal
      :is-open="isRentzModalOpen"
      :players="gameStore.players"
      @confirm="handleRentzOrderConfirm"
      @close="closeRentzModal"
    />

    <UnitsPerPlayerInputModal
      :is-open="isUnitsPerPlayerModalOpen"
      :selected-game="isUnitsPerPlayerModalOpen && (selectedGame === GAME_TYPES.DIAMONDS || selectedGame === GAME_TYPES.QUEENS || selectedGame === GAME_TYPES.LEVATE || selectedGame === GAME_TYPES.WHIST) ? selectedGame : null"
      :players="gameStore.players"
      @confirm="handleUnitsPerPlayerConfirm"
      @close="closeUnitsPerPlayerModal"
    />

    <TotalsInputModal
      :is-open="isTotalsModalOpen"
      :players="gameStore.players"
      @confirm="handleTotalsConfirm"
      @close="closeTotalsModal"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useGameStore } from '../stores/game';
import { useGameConfigStore } from '../stores/gameConfig';
import { GAME_TYPES } from '../constants/gameTypes';
import SingleCardInputModal from '../components/SingleCardInputModal.vue';
import RentzOrderModal from '../components/RentzOrderModal.vue';
import UnitsPerPlayerInputModal from '../components/UnitsPerPlayerInputModal.vue';
import TotalsInputModal from '../components/TotalsInputModal.vue';

const router = useRouter();
const gameStore = useGameStore();
const gameConfig = useGameConfigStore();
const isDouble = ref(false);
const isSingleCardModalOpen = ref(false);
const selectedGame = ref(null);
const isRentzModalOpen = ref(false);
const isUnitsPerPlayerModalOpen = ref(false);
const isTotalsModalOpen = ref(false);

onMounted(() => {
  gameStore.loadGameState();
  if (!gameStore.hasActiveGame) {
    router.push('/new-game');
  }
});

const getRankClass = (rank) => {
  switch (rank) {
    case 1:
      return 'rank-gold';
    case 2:
      return 'rank-silver';
    case 3:
      return 'rank-bronze';
    default:
      return 'rank-grey';
  }
};

const openSingleCardModal = (cardType) => {
  selectedGame.value = cardType;
  isSingleCardModalOpen.value = true;
};

const closeSingleCardModal = () => {
  isSingleCardModalOpen.value = false;
  selectedGame.value = null;
};

const openRentzModal = () => {
  isRentzModalOpen.value = true;
};

const closeRentzModal = () => {
  isRentzModalOpen.value = false;
};

const openUnitsPerPlayerModal = (gameId) => {
  selectedGame.value = gameId;
  isUnitsPerPlayerModalOpen.value = true;
};

const closeUnitsPerPlayerModal = () => {
  isUnitsPerPlayerModalOpen.value = false;
  selectedGame.value = null;
};

const openTotalsModal = () => {
  isTotalsModalOpen.value = true;
};

const closeTotalsModal = () => {
  isTotalsModalOpen.value = false;
};

const selectMiniGame = (game) => {
  switch (game.id) {
    case GAME_TYPES.KING_OF_HEARTS:
    case GAME_TYPES.TEN_OF_CLUBS:
      openSingleCardModal(game.id);
      return;
    case GAME_TYPES.RENTZ:
      openRentzModal();
      return;
    case GAME_TYPES.DIAMONDS:
    case GAME_TYPES.QUEENS:
    case GAME_TYPES.LEVATE:
    case GAME_TYPES.WHIST:
      openUnitsPerPlayerModal(game.id);
      return;
    case GAME_TYPES.TOTALS:
      openTotalsModal();
      return;
  }
};

const handleSingleCardConfirm = (playerId) => {
  const game = gameConfig.games[selectedGame.value];
  
  const scores = game.calculateScore(playerId, game.points, gameStore.players);
  gameStore.addRoundScores(scores, game.id, isDouble.value);
  gameStore.startNextRound();
  isDouble.value = false;
  closeSingleCardModal();
};

const handleRentzOrderConfirm = (orderedPlayers) => {
  const game = gameConfig.games[GAME_TYPES.RENTZ];
  const scores = game.calculateScore(orderedPlayers, game.points, gameStore.players);

  gameStore.addRoundScores(scores, GAME_TYPES.RENTZ, isDouble.value);
  gameStore.startNextRound();
  isDouble.value = false;
  closeRentzModal();
};

const handleUnitsPerPlayerConfirm = (unitsPerPlayer) => {
  const game = gameConfig.games[selectedGame.value];
  const scores = game.calculateScore(unitsPerPlayer, game.points, gameStore.players);
  gameStore.addRoundScores(scores, game.id, isDouble.value);
  gameStore.startNextRound();
  isDouble.value = false;
  closeUnitsPerPlayerModal();
}

const handleTotalsConfirm = (data) => {
  const gameId = GAME_TYPES.TOTALS;
  const game = gameConfig.games[gameId];
  const scores = game.calculateScore(data, game, gameStore.players, gameConfig);
  gameStore.addRoundScores(scores, gameId, isDouble.value);
  gameStore.startNextRound();
  isDouble.value = false;
  closeTotalsModal();
};
</script>

<style scoped>
.active-game {
  padding: 1rem;
  max-width: 800px;
  margin: 0 auto;
}

.scores-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 0.5rem;
}

.player-score {
  display: flex;
  flex-direction: column;
  padding: 0 0.75rem 0.5rem 0.75rem;
  background-color: var(--bg-color);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  position: relative;
  min-width: 200px;
}

.rank-ribbon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--text-secondary);
  background-color: var(--surface-color);
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.rank-gold {
  background-color: #ffd700;
  color: #000;
}

.rank-silver {
  background-color: #c0c0c0;
  color: #000;
}

.rank-bronze {
  background-color: #cd7f32;
  color: #000;
}

.rank-grey {
  background-color: #808080;
  color: #fff;
}

.player-header {
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 2.25rem;
}

.player-name {
  font-weight: bold;
  color: var(--text-color);
  font-size: 0.9rem;
  line-height: 32px;
}

.score-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  width: 100%;
  margin-top: 0.25rem;
}

.score-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.score-label {
  font-size: 0.7rem;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 0.25rem;
}

.round-score {
  font-size: 0.9rem;
  color: #aaa;
  padding: 0.25rem 0.5rem;
  background-color: #3d3d3d;
  border-radius: 4px;
  min-width: 2.5rem;
  text-align: center;
}

.total-score {
  font-weight: bold;
  font-size: 1.2rem;
}

.total-score.negative,
.round-score.negative {
  color: #ff4444;
}

.game-section {
  margin-top: 1.5rem;
  position: relative;
  padding-top: 1.5rem;
}

.current-player {
  margin-bottom: 0.75rem;
}

.mini-games {
  background-color: var(--bg-color);
  padding: 0.75rem;
  border-radius: 8px;
}

.mini-games h4 {
  margin-bottom: 0.75rem;
}

.mini-games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 0.5rem;
}

.mini-games-grid button {
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: var(--surface-color);
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.2s;
}

.mini-games-grid button:hover {
  background-color: var(--primary-color);
  color: white;
}

.mini-games-grid button.disabled {
  background-color: #444;
  color: #777;
  cursor: not-allowed;
}

.double-points {
  margin-bottom: 1rem;
}

.double-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
  position: relative;
}

.double-button::before {
  content: '';
  display: inline-block;
  width: 2rem;
  height: 1rem;
  background-color: var(--border-color);
  border-radius: 1rem;
  transition: all 0.2s ease;
}

.double-button::after {
  content: '';
  position: absolute;
  left: 0.75rem;
  width: 1.2rem;
  height: 1.2rem;
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: 50%;
  transition: all 0.2s ease;
  transform: translateY(-50%);
  top: 50%;
}

.double-button:not(.disabled):hover::before {
  background-color: var(--primary-color);
  opacity: 0.5;
}

.double-button.active::before {
  background-color: var(--primary-color);
}

.double-button.active::after {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transform: translateX(1rem) translateY(-50%);
}

.double-button.disabled {
  background-color: #555;
  color: #888;
  cursor: not-allowed;
}

.double-button input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.button-text {
  color: var(--text-color);
  font-size: 0.9rem;
  font-weight: 500;
  margin-left: 0.5rem;
}

.double-button.active .button-text {
  color: var(--primary-color);
}

@media (max-width: 480px) {
  .active-game {
    padding: 0.5rem;
  }

  .scores-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.375rem;
  }

  .player-score {
    min-width: unset;
    padding: 0 0.5rem 0.375rem 0.5rem;
  }

  .score-details {
    gap: 0.375rem;
  }

  .game-section {
    margin-top: 1rem;
    padding-top: 1rem;
  }

  .mini-games {
    padding: 0.75rem;
  }

  .mini-games-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.375rem;
  }

  .mini-games-grid button {
    padding: 0.5rem;
  }
}

.game-over {
  text-align: center;
  padding: 2rem;
  margin-top: 2rem;
  background-color: var(--bg-color);
  border-radius: 8px;
}

.winner {
  margin: 1.5rem 0;
}

.winner h3 {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.winner p {
  font-size: 1.2rem;
  color: var(--text-secondary);
}

.new-game-button {
  display: inline-block;
  margin-top: 1rem;
}

.new-game-button button {
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
}
</style>
