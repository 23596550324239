<template>
  <div class="home">
    <h1>Rentz Score Tracker</h1>
    <div class="game-actions">
      <router-link to="/new-game" class="action-button">
        <button>Start New Game</button>
      </router-link>
      <router-link v-if="hasSavedGame" to="/game" class="action-button">
        <button class="resume-button">Resume Game</button>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useGameStore } from '../stores/game';

const store = useGameStore();
const hasSavedGame = ref(false);

onMounted(() => {
  const savedState = localStorage.getItem('gameState');
  hasSavedGame.value = !!savedState;
});
</script>

<style scoped>
.home {
  text-align: center;
  padding: 2rem;
}

h1 {
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.game-actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-top: 2rem;
}

.action-button {
  display: inline-block;
  width: 100%;
  max-width: 300px;
}

.resume-button {
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
}

.resume-button:hover {
  background-color: var(--border-color);
}
</style>
