<template>
  <div class="app">
    <nav class="nav">
      <router-link to="/">Home</router-link>
      <router-link to="/history">History</router-link>
    </nav>
    <main class="main">
      <router-view></router-view>
    </main>
  </div>
</template>

<script setup></script>

<style>
.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.nav {
  padding: 1rem;
  background-color: var(--surface-color);
  display: flex;
  gap: 1rem;
  justify-content: center;
  border-bottom: 1px solid var(--border-color);
}

.nav a {
  color: var(--text-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.nav a:hover {
  background-color: var(--border-color);
}

.nav a.router-link-active {
  background-color: var(--primary-color);
}

.main {
  flex: 1;
  padding: 1rem;
}
</style>
