<template>
  <div v-if="isOpen" class="modal-overlay" @click="handleClose"> 
    <div class="modal-content" @click.stop>
      <div class="modal-header">
        <h3>{{ selectedGame }}</h3>
        <button class="close-button" @click="handleClose">&times;</button>
      </div>
      <div class="modal-body">
        <p class="instruction">
          {{ instructionText }}
        </p>
        <div class="players-list"> 
          <div 
            v-for="player in players" 
            :key="player.id" 
            class="player-row" 
          >
            <span class="player-name">{{ player.name }}</span>
            <button 
              class="player-units-button"
              @click="openNumberSelector(player.id)"
            >
              {{ playerUnits[player.id] ?? 0 }}
            </button>
          </div>
        </div>
        <div v-if="currentMaxUnits > 0" class="remaining-units">
          Remaining: {{ remainingUnits }}
        </div>
      </div>
      <div class="modal-footer">
        <button class="confirm-button" :disabled="!isInputValid" @click="handleConfirm">
          Confirm
        </button>
      </div>
    </div>
    <NumberSelectorModal
      :is-open="isNumberSelectorOpen"
      :max-units="currentMaxUnits"
      :current-player-name="playerToEditName"
      @select="handleNumberSelect"
      @close="closeNumberSelector"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { GAME_TYPES } from '../constants/gameTypes';
import { useGameConfigStore } from '../stores/gameConfig';
import NumberSelectorModal from './NumberSelectorModal.vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  selectedGame: {
    type: [String, null],
    required: true,
    validator: (value) => value === null || [GAME_TYPES.DIAMONDS, GAME_TYPES.QUEENS, GAME_TYPES.LEVATE, GAME_TYPES.WHIST].includes(value)
  },
  players: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(['confirm', 'close']);

const playerUnits = ref({});
const isNumberSelectorOpen = ref(false);
const playerToEditId = ref(null);

const gameConfig = useGameConfigStore();

const currentMaxUnits = computed(() => {
  const game = gameConfig.games[props.selectedGame];
  if (!game || !game.maxUnits) {
    return 0;
  }
  const playerCount = props.players.length;
  return game.maxUnits(playerCount);
});

const remainingUnits = computed(() => {
  const totalAssigned = Object.values(playerUnits.value).reduce((sum, units) => sum + (units || 0), 0);
  return currentMaxUnits.value - totalAssigned;
});

watch([() => props.players, () => props.isOpen], ([newPlayers, newIsOpen]) => {
  if (newIsOpen) {
    playerUnits.value = newPlayers.reduce((acc, player) => {
      acc[player.id] = 0;
      return acc;
    }, {});
    isNumberSelectorOpen.value = false;
    playerToEditId.value = null;
  } else {
    playerUnits.value = {};
    isNumberSelectorOpen.value = false;
    playerToEditId.value = null;
  }
}, { deep: true, immediate: true }); 

const instructionText = computed(() => {
  switch (props.selectedGame) {
    case GAME_TYPES.DIAMONDS:
      return 'How many diamonds did each player take?';
    case GAME_TYPES.QUEENS:
      return 'How many queens did each player take?';
    case GAME_TYPES.LEVATE:
    case GAME_TYPES.WHIST:
      return 'How many hands did each player make?';
    default:
      return 'Enter units per player:';
  }
});

const playerToEditName = computed(() => {
  if (!playerToEditId.value) return '';
  const player = props.players.find(p => p.id === playerToEditId.value);
  return player ? player.name : '';
});

const openNumberSelector = (playerId) => {
  if (currentMaxUnits.value <= 0) return; 
  playerToEditId.value = playerId;
  isNumberSelectorOpen.value = true;
};

const closeNumberSelector = () => {
  isNumberSelectorOpen.value = false;
  playerToEditId.value = null;
};

const handleNumberSelect = (number) => {
  if (playerToEditId.value) {
    playerUnits.value[playerToEditId.value] = number;
  }
  closeNumberSelector();
};

const isInputValid = computed(() => {
  if (!props.players || props.players.length === 0) return false;
  
  const totalUnitsAssigned = Object.values(playerUnits.value).reduce((sum, units) => sum + (units || 0), 0);
  
  return totalUnitsAssigned === currentMaxUnits.value;
});

const handleConfirm = () => {
  if (isInputValid.value && remainingUnits.value === 0) {
    emit('confirm', { ...playerUnits.value });
  }
};

const handleClose = () => {
  emit('close');
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--bg-color);
  border-radius: 8px;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
  color: var(--text-color);
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.25rem;
  line-height: 1;
}

.close-button:hover {
  color: var(--text-color);
}

.instruction {
  color: var(--text-secondary);
  margin: 0 0 1rem 0; 
  text-align: center;
}

.players-list { 
  display: flex;
  flex-direction: column; 
  gap: 0.75rem;
  margin: 1rem 0;
}

.player-row {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding: 0.5rem 0; 
  border-bottom: 1px solid var(--border-color); 
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.player-row:last-child {
  border-bottom: none; 
}

.player-name {
  color: var(--text-color);
  flex-grow: 1; 
  margin-right: 1rem; 
}

.player-units-button {
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  cursor: pointer;
  min-width: 40px; 
  text-align: center;
  font-weight: bold;
  transition: background-color 0.2s ease;
}

.player-units-button:hover {
  background-color: var(--border-color);
}

.remaining-units {
  text-align: center;
  margin-top: 1rem;
  font-weight: bold;
  color: var(--text-secondary);
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.confirm-button {
  padding: 0.75rem 1.5rem;
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  color: var(--text-color);
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.confirm-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.confirm-button:not(:disabled):hover {
  opacity: 0.9;
}

@media (max-width: 480px) {
  .modal-content {
    padding: 1rem;
    width: 95%;
  }
  .player-row {
    padding: 0.3rem 0;
  }
}
</style>