<template>
  <div class="mini-game-selection">
    <h3>Select Mini Games</h3>
    <div class="mini-games-list">
      <label v-for="game in availableMiniGames" :key="game.id" class="mini-game-option">
        <input type="checkbox" v-model="selectedMiniGames" :value="game.id" />
        {{ game.displayName }}
      </label>
    </div>
    <button :disabled="selectedMiniGames.length === 0" @click="startMiniGame">Start Game</button>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useGameStore } from '../stores/game';

const router = useRouter();
const store = useGameStore();

const availableMiniGames = ref(store.availableMiniGames);
const selectedMiniGames = ref([]);

const startMiniGame = () => {
  if (selectedMiniGames.value.length > 0) {
    store.setMiniGames(selectedMiniGames.value);
    router.push('/game');
  }
};
</script>

<style scoped>
.mini-game-selection {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.mini-games-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.mini-game-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--surface-color);
  padding: 0.5rem;
  border-radius: 4px;
}

.mini-game-option input[type='checkbox'] {
  accent-color: var(--primary-color);
}

button {
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #444;
  color: #777;
  cursor: not-allowed;
}
</style>
