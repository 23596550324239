import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import NewGame from '../views/NewGame.vue';
import ActiveGame from '../views/ActiveGame.vue';
import History from '../views/History.vue';
import MiniGameSelection from '../views/MiniGameSelection.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/new-game',
      name: 'new-game',
      component: NewGame
    },
    {
      path: '/select-mini-games',
      name: 'select-mini-games',
      component: MiniGameSelection
    },
    {
      path: '/game',
      name: 'game',
      component: ActiveGame
    },
    {
      path: '/history',
      name: 'history',
      component: History
    }
  ]
});

export default router;
