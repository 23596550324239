<template>
  <div class="history">
    <h2>Game History</h2>
    <div class="history-content">
      <p>Game history will be displayed here...</p>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.history {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
}

.history-content {
  margin-top: 1rem;
}
</style>
